const SvgComponent = ({
  svgContent,
  className = '',
  width = '100%',
  height = '100%',
  scale = '1',
  ...props
}) => {
  // Check if svgContent is a string that starts with '<' (indicating inline SVG)
  const isInlineSvg = typeof svgContent === 'string' && svgContent.startsWith('<');

  return (
    <div className={className} style={{ width, height, transform: `scale(${scale})` }}>
      {isInlineSvg ? (
        <div dangerouslySetInnerHTML={{ __html: svgContent }} />
      ) : (
        <img src={svgContent} alt="SVG" style={{ width: '100%', height: '100%' }} />
      )}
    </div>
  );
};

export default SvgComponent;
