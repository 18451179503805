import moment from 'moment';
import React from 'react';
import SvgComponent from '../../../components/SvgComponent/SvgComponent';
import SAR from '../../../assets/icon/SAR.svg';

const InvoiceSummary = ({ bookingDetails }) => {
  const getCost = (type: string) => {
    let total = bookingDetails?.selectedSubServices?.reduce((total, item: any) => {
      const isDiscountApplied = moment(item.validTillDate).add(1, 'days').isAfter(moment());
      return total + (isDiscountApplied ? item.price : item.originalPrice);
    }, 0);

    if (type === 'before') {
      return total + (total * bookingDetails?.VAT || 0) / 100;
    } else {
      if (bookingDetails?.salonDiscount > 0) {
        const discountAmount = (bookingDetails?.salonDiscount / 100) * total;
        const totalCost = total - discountAmount;
        total = Number(totalCost);
      }

      if (bookingDetails?.bbookDiscount > 0) {
        const discountAmount = (bookingDetails?.bbookDiscount / 100) * total;
        const totalCost = total - discountAmount;
        total = Number(totalCost);
      }

      return total + (total * bookingDetails?.VAT || 0) / 100;
    }
  };

  return (
    <div
      style={{
        margin: '20px auto',
        padding: '15px',
        border: '1px solid #ddd',
        borderRadius: '10px',
        boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          color: '#6A1B9A',
          marginBottom: '5px',
        }}
      >
        <span>Total Before Discount {bookingDetails?.VAT ? 'with VAT' : ''}</span>
        <span>
          <div className="d-flex justify-content-center align-items-center">
            <SvgComponent svgContent={SAR} className="sar-icon" scale=".69" width="1rem" />
            <span>{Number(getCost('before')).toFixed(2)} </span>
          </div>
        </span>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          color: '#6A1B9A',
          marginBottom: '5px',
        }}
      >
        <span>Total After Discount {bookingDetails?.VAT ? 'with VAT' : ''}</span>
        <span>
          <div className="d-flex justify-content-center align-items-center">
            <SvgComponent svgContent={SAR} className="sar-icon" scale=".69" width="1rem" />
            <span>{Number(getCost('after')).toFixed(2)} </span>
          </div>
        </span>
      </div>
      <hr style={{ margin: '10px 0', borderColor: '#ddd' }} />

      {/* Discounts */}

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          color: '#4CAF50',
          marginBottom: '5px',
        }}
      >
        <span> Yous Saved {bookingDetails?.bbookDiscount} % </span>
        <span>
          <div className="d-flex justify-content-center align-items-center">
            <SvgComponent svgContent={SAR} className="sar-icon" scale=".69" width="1rem" />
            <span>{Number(getCost('before') - getCost('after')).toFixed(2)} </span>
          </div>
        </span>
      </div>

      <hr style={{ margin: '10px 0', borderColor: '#ddd' }} />

      {/* Total */}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>Points</span>
        <span>{Number(getCost('after')).toFixed(0)} Point</span>
      </div>
    </div>
  );
};

export default InvoiceSummary;
