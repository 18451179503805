import validator from 'validator';

export const isEmailValid = (email: string) => {
  return validator.isEmail(email);
};

export const isContactNumberValid = (contactNumber: string) => {
  return /5[0-9]{8}/.test(contactNumber);
  //return validator.isMobilePhone(contactNumber)
  // return validator.isMobilePhone(contactNumber, 'en-IN')
};

export const isInputNumber = (value: string) => {
  return validator.isNumeric(value);
};

export const arabicLettersOnlyRegex = /^[\u0600-\u0659\u0621-\u064A\s ]+$/;
export const englishLettersOnlyRegex = /^[a-zA-Z ]+$/;
export const arabicLettersWithSymbolsRegex =
  /^(?=.*[\u0600-\u0659\u0621-\u064A])[\u0600-\u0659\u0621-\u064A\s\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]+$/;
export const englishLettersWithSymbolsRegex =
  /^(?=.*[a-zA-Z])[a-zA-Z\s\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]+$/;
export const englishLettersSymbolsAndNumberOnlyRegex = /^[a-zA-Z0-9 !@#$%^&*]+$/;
export const arabicLettersSymbolsAndNumberOnlyRegex =
  /^[\u0621-\u064A\u0660-\u0669 !@#$%^&*1234567890]+$/;
export const englishLettersSymbolsAndNumberOnlyRegexCMS = /^[a-zA-Z0-9 !@#$%^&()',.:"?!*]+$/;
export const arabicLettersSymbolsAndNumberOnlyRegexCMS =
  /^[\u0621-\u064A\u0660-\u0669 !@#$()',.:"?!%^&*1234567890]+$/;
export const arabicAndEnglishLettersOnlyRegex = /^[a-zA-Z\u0600-\u0659\u0621-\u064A ]+$/;
export const englishLettersAndNumbersRegex = /^[a-zA-Z0-9_\s ]*$/;
export const arabicLettersAndNumbersRegex = /^[\u0621-\u064A\u0660-\u0669 ]+$/;
export const saudiaPhoneNumber = /^5[0-9]{8}/;
export const emailValidation =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const crnRegex = /^\d{10}$/;
export const licenseRegex = /^[a-zA-Z0-9]{1,15}$/;
export const alphanumericWithWhitespaceRegex = /^[a-zA-Z0-9_\s]*$/;

export const noArabicLetters = /^[^\u0600-\u06FF]*$/;
export const noEnglishLetters = /^[^a-zA-Z]*$/;
